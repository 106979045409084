/**
 * --------------------------------------------
 * AdminLTE Toasts.js
 * License MIT
 * --------------------------------------------
 */

const Toasts = (($) => {
  /**
   * Constants
   * ====================================================
   */

  const NAME               = 'Toasts'
  const DATA_KEY           = 'lte.toasts'
  const EVENT_KEY          = `.${DATA_KEY}`
  const JQUERY_NO_CONFLICT = $.fn[NAME]

  const Event = {
    INIT: `init${EVENT_KEY}`,
    CREATED: `created${EVENT_KEY}`,
    REMOVED: `removed${EVENT_KEY}`,
  }

  const Selector = {
    BODY: 'toast-body',
    CONTAINER_TOP_RIGHT: '#toastsContainerTopRight',
    CONTAINER_TOP_LEFT: '#toastsContainerTopLeft',
    CONTAINER_BOTTOM_RIGHT: '#toastsContainerBottomRight',
    CONTAINER_BOTTOM_LEFT: '#toastsContainerBottomLeft',
  }

  const ClassName = {
    TOP_RIGHT: 'toasts-top-right',
    TOP_LEFT: 'toasts-top-left',
    BOTTOM_RIGHT: 'toasts-bottom-right',
    BOTTOM_LEFT: 'toasts-bottom-left',
    FADE: 'fade',
  }

  const Position = {
    TOP_RIGHT: 'topRight',
    TOP_LEFT: 'topLeft',
    BOTTOM_RIGHT: 'bottomRight',
    BOTTOM_LEFT: 'bottomLeft',
  }

  const Id = {
    CONTAINER_TOP_RIGHT: 'toastsContainerTopRight',
    CONTAINER_TOP_LEFT: 'toastsContainerTopLeft',
    CONTAINER_BOTTOM_RIGHT: 'toastsContainerBottomRight',
    CONTAINER_BOTTOM_LEFT: 'toastsContainerBottomLeft',
  }

  const Default = {
    position: Position.TOP_RIGHT,
    fixed: true,
    autohide: false,
    autoremove: true,
    delay: 1000,
    fade: true,
    icon: null,
    image: null,
    imageAlt: null,
    imageHeight: '25px',
    title: null,
    subtitle: null,
    close: true,
    body: null,
    class: null,
  }

  /**
   * Class Definition
   * ====================================================
   */
  class Toasts {
    constructor(element, config) {
      this._config  = config

      this._prepareContainer();

      const initEvent = $.Event(Event.INIT)
      $('body').trigger(initEvent)
    }

    // Public

    create() {
      var toast = $('<div class="toast" role="alert" aria-live="assertive" aria-atomic="true"></div>')

toast.data ('autohide', this._config.autohide)
      toast.data ('एनीमेशन', this._config.fade)
      
अगर (this._config.क्लास) {
        टोस्ट.ऐडक्लास (this._config.क्लास)
      }

अगर (this._config.देरी && this._config.देरी!= 500) {
        toast.data ('देरी', this._config.delay)
      }

var toast_header = $('<div class="toast-header">')

अगर (this._config.image != शून्य) {
        var toast_image = $('<img>').addClass('गोल mr-2').attr('src', this._config.image).attr('alt', this._config.imageAlt)
        
अगर (this._config.imageHeight!= शून्य) {
          toast_image.ऊंचाई (this._config.imageHeight).चौड़ाई ('ऑटो')
        }

toast_header.परिशिष्ट(toast_image)
      }

अगर (this._config.icon != शून्य) {
        toast_header.परिशिष्ट($('<i></i>').addClass ('mr-2').addClass(this._config.icon))
      }

अगर (this._config.शीर्षक!= शून्य) {
        toast_header.परिशिष्ट($('<strong></strong>').addClass ('mr-auto').html(this._config.title))
      }

अगर (this._config.उपशीर्षक!= शून्य) {
        toast_header.परिशिष्ट($('<small></small>').html(this._config.subtitle))
      }

अगर (this._config.close == सत्य) {
        var toast_close = $('<button data-dismiss="toast"></button>').attr('type', 'button').addClass('ml-2 mb-1 close').attr('aria-label', 'Close').append('<span aria-hidden="true">×</span>')
        
अगर (this._config.title == शून्य) {
          toast_close.toggleClass ('ml-2 ml-auto')
        }
        
toast_header.परिशिष्ट(toast_close)
      }

टोस्ट.परिशिष्ट(toast_header)

अगर (this._config.body != null) {
        टोस्ट.एपेंड($('<div class="toast-body"></div>').html(this._config.body))
      }

      $(this._getContainerId()).prepend(toast)

      const createdEvent = $.Event(Event.CREATED)
      $('body').trigger(createdEvent)

      toast.toast('show')


      if (this._config.autoremove) {
        toast.on('hidden.bs.toast', function () {
          $(this).delay(200).remove();

          const removedEvent = $.Event(Event.REMOVED)
          $('body').trigger(removedEvent)
        })
      }


    }

    // Static

    _getContainerId() {
      if (this._config.position == Position.TOP_RIGHT) {
        return Selector.CONTAINER_TOP_RIGHT;
      } else if (this._config.position == Position.TOP_LEFT) {
        return Selector.CONTAINER_TOP_LEFT;
      } else if (this._config.position == Position.BOTTOM_RIGHT) {
        return Selector.CONTAINER_BOTTOM_RIGHT;
      } else if (this._config.position == Position.BOTTOM_LEFT) {
        return Selector.CONTAINER_BOTTOM_LEFT;
      }
    }

    _prepareContainer() {
      if ($(this._getContainerId()).length === 0) {
        var container = $('<div></div>').attr('id', this._getContainerId().replace('#', ''))
        अगर (this._config.स्थिति == Position.TOP_RIGHT) {
          container.addClass(ClassName.TOP_RIGHT)
        } और अगर (this._config.स्थिति == Position.TOP_LEFT) {
          container.addClass(ClassName.TOP_LEFT)
        } और अगर (this._config.स्थिति == Position.BOTTOM_RIGHT) {
          container.addClass(ClassName.BOTTOM_RIGHT)
        } और अगर (this._config.स्थिति == Position.BOTTOM_LEFT) {
          container.addClass(ClassName.BOTTOM_LEFT)
        }

$('body').append(container)
      }

अगर (this._config.fixed) {
        $(this._getContainerId()).addClass('fixed')
      और} {
        $(this._getContainerId()).removeClass('fixed')
      }
    }

स्थिर

स्थैतिक _jQueryInterface (विकल्प, कॉन्फ़िगरेशन) {
      this.each(फ़ंक्शन () {
        const _options = $.extend({}, डिफ़ॉल्ट, कॉन्फ़िगरेशन)
        वर टोस्ट = नए टोस्ट ($ (यह), _options)

अगर (विकल्प === 'बनाएं') {
          टोस्ट [विकल्प] ()
        }
      })
    }
  }

/**
   * jQuery एपीआई
   * ====================================================
   */

$.fn[नाम] = Toasts._jQueryInterface
  $.fn[NAME] का उपयोग करें। कन्स्ट्रक्टर = टोस्ट
  $.fn[NAME].noConflict = फ़ंक्शन () {
    $.fn[नाम] = JQUERY_NO_CONFLICT
    वापसी Toasts._jQueryInterface
  }

टोस्ट लौटाएं
})(jQuery)

निर्यात डिफ़ॉल्ट टोस्ट
</div>